import styles from './cashback-banner.module.css';

import classNames from 'classnames';
import { ReactElement } from 'react';

import { FLATLIST_TEXT_CASHBACK_BADGE, FLATLIST_TEXT_CASHBACK_TITLE } from 'core/constants/flat-list';

import CustomLink from 'components/common/link/link';
import { Text, TextColors } from 'components/common/text/text';

interface CashbackBannerProps {
  className?: string;
  flatCard: ReactElement;
}

export const CashbackBanner = (props: CashbackBannerProps) => {
  return (
    <>
      <li className={classNames(styles.root, props.className)}>
        <CustomLink className={styles.wrapper} href='/info/help-guest-cashback/'>
          <>
            <div className={styles.cashbackIconWrapper}>
              <div className={styles.cashbackIcon} />
            </div>
            <div className={styles.textContent}>
              <Text className={styles.title} As='h2' size={16} color={TextColors.white}>
                {FLATLIST_TEXT_CASHBACK_TITLE}
              </Text>
              <div className={styles.badge}>
                <Text className={styles.badgeText} size={16} color={TextColors.white}>
                  {FLATLIST_TEXT_CASHBACK_BADGE}
                </Text>{' '}
                <span className={styles.icon} />
              </div>
            </div>
          </>
        </CustomLink>
      </li>
      {props.flatCard}
    </>
  );
};
