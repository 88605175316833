import { BannerCardTemplate } from 'components/flat/banner-card-template/banner-card-template';

interface FlatFilterCardProps {
  backgroundColor: string;
  infoText: string;
  badgeText: string;
  onClick: () => void;
  className?: string;
}

export const FlatFilterCard = ({ backgroundColor, infoText, badgeText, onClick, className }: FlatFilterCardProps) => {
  const handleClick = () => {
    setTimeout(() => {
      onClick();
    }, 0);
  };

  return (
    <li className={className} onClick={handleClick}>
      <BannerCardTemplate backgroundColor={backgroundColor} badgeText={badgeText} title={infoText} />
    </li>
  );
};
