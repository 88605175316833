import styles from './flat-list.module.css';

import classNames from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';

import { DEFAULT_CURRENCY } from 'core/constants/default-values';
import { CardFlat } from 'core/entities/flats';
import { City } from 'core/entities/geo';
import { SeoCards } from 'core/entities/seo';
import { getPositionInformers } from 'core/utils/flat/get-position-informers';

import { CommonContext } from 'contexts/common';

import { useRetinaDisplay } from 'hooks/photos/use-retina-display';
import { useBreakpoints } from 'hooks/use-window-dimensions';

import { FlatListItem } from 'components/flat/flat-list/flat-list-item/flat-list-item';

interface FlatListProps {
  className?: string;
  flatList: Array<CardFlat>;
  mainHost: string;
  isMobileDevice: boolean;
  city?: Optional<City>;
  hasMaskWhenRemove?: boolean;
  starredIdList?: Array<number>;
  dateDuration?: Optional<number>;
  seoCards?: SeoCards;
  withPreview?: boolean;
  filtersApplied?: boolean;
  showCashbackBanner?: boolean;
  showSkeleton?: boolean;

  onCalendarFilterClick?: () => void;
  onFlatClick?: Optional<(flat: CardFlat) => void>;
  showCalendarFilter?: boolean;

  onGuestsFilterClick?: () => void;
  showGuestsFilter?: boolean;

  onVisibilityChange?: (visibility: boolean, flat: CardFlat) => void;
}

export const FlatList = (props: FlatListProps) => {
  const { hasMaskWhenRemove = false } = props;
  const { context } = useContext(CommonContext);
  const [cardsPerLine, setCardsPerLine] = useState<1 | 2 | 3>(3);
  const { isMobile, isTablet } = useBreakpoints();
  const isRetina = useRetinaDisplay();

  const currency = context ? context.currencies.current : DEFAULT_CURRENCY;

  useEffect(() => {
    if (isMobile) {
      setCardsPerLine(1);
    } else if (isTablet) {
      setCardsPerLine(2);
    } else {
      setCardsPerLine(3);
    }
  }, [isMobile, isTablet]);

  const isStarred = useCallback((flatId: number) => {
    if (props.starredIdList) {
      return props.starredIdList.some((id) => id === flatId);
    }
    return true;
  }, []);

  const { isLastLineCard, shouldShow, position } = getPositionInformers(
    cardsPerLine,
    {
      items: props.flatList,
      end: !props.withPreview
    },
    props.seoCards ? props.seoCards : null,
    {
      calendarFilter: !!props.showCalendarFilter,
      guestsFilter: !!props.showGuestsFilter,
      cashbackBanner: !!props.showCashbackBanner
    }
  );

  return (
    <ul className={classNames(styles.root, props.className)}>
      {props.flatList.map((flat, index) => {
        const flatId = props.showSkeleton ? index : flat.id;
        const checkIsStarred = () => isStarred(flatId);

        if (shouldShow(index)) {
          return (
            <FlatListItem
              mainHost={props.mainHost}
              key={flatId}
              index={index}
              flat={props.showSkeleton ? null : flat}
              currency={currency}
              isLastLine={isLastLineCard(index)}
              gaData={{
                position: position(index),
                filtersEnabled: !!props.filtersApplied,
                cityId: props.city ? props.city.id : 0
              }}
              isStarred={checkIsStarred}
              isRetina={isRetina}
              isMobileDevice={props.isMobileDevice}
              onGuestsFilterClick={props.onGuestsFilterClick}
              onCalendarFilterClick={props.onCalendarFilterClick}
              onFlatClick={props.onFlatClick}
              onVisibilityChange={props.onVisibilityChange}
              showGuestsFilter={props.showGuestsFilter}
              showCalendarFilter={props.showCalendarFilter}
              withPreview={props.withPreview}
              seoCards={props.seoCards}
              hasMaskWhenRemove={hasMaskWhenRemove}
              dateDuration={props.dateDuration}
              showCashbackBanner={props.showCashbackBanner}
            />
          );
        }
        return null;
      })}
    </ul>
  );
};
