import styles from './skeleton.module.css';

import classNames from 'classnames';

interface SkeletonProps {
  className?: string;
  width?: string;
}

export const Skeleton = ({ className, width = '100%' }: SkeletonProps) => {
  return <div className={classNames(className, styles.root)} style={{ width }}></div>;
};
