import styles from './banner-card-template.module.css';

import classNames from 'classnames';

import { Text, TextColors } from 'components/common/text/text';

interface BannerCardTemplateProps {
  backgroundColor: string;
  badgeText: string;
  title: string;
  titleClassName?: string;
  wrapperClassName?: string;
}

export const BannerCardTemplate = ({
  backgroundColor,
  badgeText,
  title,
  titleClassName,
  wrapperClassName
}: BannerCardTemplateProps) => {
  return (
    <div className={classNames(styles.wrapper, wrapperClassName)} style={{ backgroundColor: backgroundColor }}>
      <Text className={classNames(styles.title, titleClassName)} As='h2' size={16} color={TextColors.white}>
        {title}
      </Text>
      <div className={styles.badge}>
        <Text className={styles.badgeText} size={16} color={TextColors.white}>
          {badgeText}
        </Text>
        <span className={styles.icon} />
      </div>
    </div>
  );
};
