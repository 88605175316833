import styles from './flat-list-item.module.css';

import classNames from 'classnames';
import { ReactElement } from 'react';

import {
  FLATLIST_TEXT_CALENDAR_FILTER_CARD_BADGE,
  FLATLIST_TEXT_CALENDAR_FILTER_CARD_TITLE,
  FLATLIST_TEXT_GUESTS_FILTER_CARD_BADGE,
  FLATLIST_TEXT_GUESTS_FILTER_CARD_TITLE
} from 'core/constants/flat-list';
import { Currency } from 'core/entities';
import { CardFlat } from 'core/entities/flats';
import { SeoCards, SeoCardTypes } from 'core/entities/seo';
import { NOOP } from 'core/utils/NOOP';

import { FlatCard, FlatGaData } from 'components/flat/flat-card/flat-card';
import { FlatFilterCard } from 'components/flat/flat-filter-card/flat-filter-card';
import { CashbackBanner } from 'components/flat/flat-list/cashback-banner/cashback-banner';
import { SeoCard } from 'components/flat/seo-card/seo-card';
import { SkeletonFlatCard } from 'components/skeleton/skeleton-flat-card/skeleton-flat-card';

interface FlatListItemProps {
  mainHost: string;
  index: number;
  flat: Optional<CardFlat>;
  currency: Currency;
  isLastLine: boolean;
  gaData: FlatGaData;
  seoCards?: SeoCards;
  isStarred: () => boolean;
  isRetina: Optional<boolean>;
  isMobileDevice: boolean;
  dateDuration?: Optional<number>;
  hasMaskWhenRemove?: boolean;
  withPreview?: boolean;
  onVisibilityChange?: (visibility: boolean, flat: CardFlat) => void;
  onCalendarFilterClick?: () => void;
  onFlatClick?: Optional<(flat: CardFlat) => void>;
  showCalendarFilter?: boolean;
  onGuestsFilterClick?: () => void;
  showGuestsFilter?: boolean;
  showCashbackBanner?: boolean;
}

// eslint-disable-next-line complexity
export const FlatListItem = (props: FlatListItemProps) => {
  const seoCard = props.seoCards ? props.seoCards[props.index] : null;

  const flatCard = props.flat ? (
    <FlatCard
      className={classNames(styles.item, styles.card)}
      mainHost={props.mainHost}
      flat={props.flat}
      currency={props.currency}
      isStarred={props.isStarred}
      isRetina={props.isRetina}
      isMobileDevice={props.isMobileDevice}
      dateDuration={props.dateDuration}
      hasMaskWhenRemove={props.hasMaskWhenRemove}
      isPreview={props.withPreview && props.isLastLine}
      onVisibilityChange={props.onVisibilityChange}
      gaData={{ ...props.gaData, index: props.index + 1 }}
      onFlatClick={props.onFlatClick}
    />
  ) : (
    <SkeletonFlatCard className={classNames(styles.item, styles.card)} withCashback={props.showCashbackBanner} />
  );

  if (props.index === 0 && props.showCashbackBanner && props.flat) {
    return <CashbackBanner className={classNames(styles.item, styles.filterCard)} flatCard={flatCard} />;
  }

  if (showCalendarFilterCard(props.index, Boolean(props.showCalendarFilter))) {
    return <CalendarFilterCard index={props.index} flatCard={flatCard} onClick={props.onCalendarFilterClick} />;
  }

  if (showGuestFilterCard(props.index, Boolean(props.showCalendarFilter), Boolean(props.showGuestsFilter))) {
    return <GuestFilterCard index={props.index} flatCard={flatCard} onClick={props.onGuestsFilterClick} />;
  }

  if (seoCard && seoCard.type === SeoCardTypes.cityArticle) {
    return (
      <>
        <SeoCard className={classNames(styles.item, styles.seoCard)} key={`seo_${props.index}`} card={seoCard} />
        {flatCard}
      </>
    );
  }
  return flatCard;
};

interface FilterCardProps {
  index: number;
  onClick?: () => void;
  flatCard: ReactElement;
}

const showCalendarFilterCard = (index: number, showCalendarFilter: boolean) => {
  return index === 2 && showCalendarFilter;
};

const CalendarFilterCard = (props: FilterCardProps) => (
  <>
    <FlatFilterCard
      className={classNames(styles.item, styles.filterCard)}
      key={`filter_${props.index}`}
      infoText={FLATLIST_TEXT_CALENDAR_FILTER_CARD_TITLE}
      badgeText={FLATLIST_TEXT_CALENDAR_FILTER_CARD_BADGE}
      backgroundColor='#FC4355'
      onClick={props.onClick ? props.onClick : NOOP}
    />
    {props.flatCard}
  </>
);

const showGuestFilterCard = (index: number, showCalendarFilter: boolean, showGuestsFilter: boolean) => {
  return (
    (index === 2 && !showCalendarFilter && showGuestsFilter) || (index === 4 && showCalendarFilter && showGuestsFilter)
  );
};

const GuestFilterCard = (props: FilterCardProps) => (
  <>
    <FlatFilterCard
      className={classNames(styles.item, styles.filterCard)}
      key={`filter_${props.index}`}
      infoText={FLATLIST_TEXT_GUESTS_FILTER_CARD_TITLE}
      badgeText={FLATLIST_TEXT_GUESTS_FILTER_CARD_BADGE}
      backgroundColor='#FF006B'
      onClick={props.onClick ? props.onClick : NOOP}
    />
    {props.flatCard}
  </>
);
