import styles from './skeleton-flat-card.module.css';

import classNames from 'classnames';

import { Skeleton } from 'components/skeleton/skeleton';

interface SkeletonFlatCardProps {
  className?: string;
  withCashback?: boolean;
}

export const SkeletonFlatCard = ({ className, withCashback = false }: SkeletonFlatCardProps) => {
  return (
    <li className={classNames(styles.root, className)}>
      <Skeleton className={styles.image} />
      <div className={styles.info}>
        <Skeleton className={styles.text} width='100%' />
        <Skeleton className={styles.text} width='80%' />
        <Skeleton className={styles.text} width='40%' />
        {withCashback && <Skeleton className={styles.text} width='50%' />}
      </div>
    </li>
  );
};
