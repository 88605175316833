export interface SEO {
  title: string;
  headline: string;
  noticeTop: string;
  noticeDown: string;
  description: string;
  keywords: string;
  seoCards: {
    cards: SeoCards;
  };
  canonical: string;
  is_landing?: boolean;
  filter_params?: string;
  maxPrice?: number;
  minPrice?: number;
  reviewsAverage?: number;
  reviewsCount?: number;
}

export interface SEOResponse {
  seo: SEO;
  'set-cookie': string[];
}

export type SeoCards = {
  [index: string]: SeoCard;
};

export enum SeoCardTypes {
  cityArticle = 'CITY_ARTICLE',
  seoMain = 'SEO_MAIN'
}

export interface SeoCard {
  type: SeoCardTypes;
  content: {
    color: string;
    text: string;
    headline?: string;
    articleURL?: string;
    photoURL?: string;
  };
}

export class SeoRedirectError extends Error {
  constructor(url: string) {
    super();
    this.message = url;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class Seo404Error extends Error {}
