import styles from './seo-card.module.css';

import classNames from 'classnames';

import { SeoCard as SeoCardType, SeoCardTypes } from 'core/entities/seo';

import CustomLink from 'components/common/link/link';
import { BannerCardTemplate } from 'components/flat/banner-card-template/banner-card-template';

interface SeoCardProps {
  className?: string;
  card: SeoCardType;
}

const BANNER_TITLE = 'Путеводитель';

export const SeoCard = ({ className, card }: SeoCardProps) => {
  const { photoURL } = card.content;

  if (card.type === SeoCardTypes.cityArticle) {
    return (
      <li className={classNames(styles.root, styles.article, className)}>
        <div
          className={classNames(styles.wrapper, { [styles.wrapperWithoutImg]: !photoURL })}
          style={{ backgroundColor: `#${card.content.color}` }}
        >
          <CustomLink className={styles.link} href={card.content.articleURL ? card.content.articleURL : ''} external>
            <>
              {photoURL && (
                <div className={styles.imageWrapper}>
                  <div className={styles.image} style={{ backgroundImage: photoURL ? `url(${photoURL})` : 'none' }} />
                </div>
              )}
              <BannerCardTemplate
                backgroundColor={card.content.color}
                badgeText={BANNER_TITLE}
                title={card.content.text}
                titleClassName={classNames({ [styles.title]: photoURL })}
                wrapperClassName={classNames({
                  [styles.banner]: photoURL,
                  [styles.bannerWithoutImg]: !photoURL
                })}
              />
            </>
          </CustomLink>
        </div>
      </li>
    );
  }

  return null;
};
