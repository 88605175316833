import { CardFlat } from 'core/entities/flats';
import { SeoCards } from 'core/entities/seo';

interface FlatsInterface {
  items: CardFlat[];
  end: boolean;
}

const countLessThan = (array: Array<string>, border: number): number =>
  array.reduce((acc, curr) => (Number(curr) <= border ? acc + 1 : acc), 0);

export const getPositionInformers = (
  cardsPerLine: number,
  flats: FlatsInterface,
  seoCards: Optional<SeoCards>,
  otherCards: {
    calendarFilter: boolean;
    guestsFilter: boolean;
    cashbackBanner: boolean;
  }
) => {
  const hasCashbackBanner = otherCards.cashbackBanner;
  const seoCardsPositions = seoCards ? Object.keys(seoCards).slice(1) : [];
  const seoCardsAmount = seoCardsPositions.length;
  const flatsAmount = flats.items.length;
  let otherCardsAmount = Object.values(otherCards).filter((card) => card).length;
  if (hasCashbackBanner && otherCardsAmount > 1 && cardsPerLine === 3) {
    otherCardsAmount = otherCardsAmount - 1;
  }
  const allCardsAmount = flatsAmount + seoCardsAmount + otherCardsAmount;

  const visibleCards = flatsAmount - (allCardsAmount % cardsPerLine);

  return {
    isLastLineCard: (index: number) => !flats.end && cardsPerLine >= visibleCards - index,
    shouldShow: (index: number) => flats.end || visibleCards > index,
    position: (index: number) => {
      const position = index + countLessThan(seoCardsPositions, index);
      return {
        row: Math.floor(position / cardsPerLine) + 1,
        column: (position % cardsPerLine) + 1
      };
    }
  };
};
